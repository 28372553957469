import HelpCenter from '../'

export default [
  {
    name: 'HelpCenter',
    component: HelpCenter,
    path: '/helpcenter',
    meta: {
      slug: 'helpcenter',
      page: 'helpcenter'
    }
  }
]
