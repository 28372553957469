import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'

export default {
  namespaced: true,

  state: {
    allCategoryList: [],
    allDocumentList: []
  },

  getters: {
    allCategoryList: state => state.allCategoryList,
    allDocumentList: state => state.allDocumentList,
    isEmptyDocumentList: state => state.allDocumentList.length === 0
  },

  actions: {
    async getAllCategories({ commit, dispatch }) {
      const results = await requestHandler({
        title: 'getAllCategories',
        methodName: 'getAllCategories',
        method: () => {
          return HTTP.get('Help/category')
        },
        success: result => {
          commit('SET_CATEGORIES', result.data)
          return result
        }
      })
      return results
    },
    async getAllDocuments({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getAllDocuments',
        methodName: 'getAllDocuments',
        method: () => {
          return HTTP.get('Help/document', {
            params: { ...payload }
          })
        },
        success: result => {
          commit('SET_DOCUMENTS', result.data)
          return result
        }
      })
      return results
    }
  },

  mutations: {
    SET_CATEGORIES(state, data) {
      state.allCategoryList = data.categoryList
    },
    SET_DOCUMENTS(state, data) {
      state.allDocumentList = data.helpDocumentList
    }
  }
}
